import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import Select from 'react-select';
import NotificationAlert from "react-notification-alert";
import { activeReminderC, starLoadingRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import moment from 'moment'
import '../../../styles/RBCheckboxFormStyles.css';
import '../../../cssResponsive/remindersContactSection/remindersContacto.css';
import { Checkbox } from '../../collegeComponents/AddOrEditCollege';
import { starLoadingAllRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import { starLoadingProspectRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import { starLoadingApplicationRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import {
    BrowserRouter as Router, Switch,
    Route, Link, useLocation
} from 'react-router-dom';
import { useParams, } from "react-router";
import { setRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import { starLoadingTrackingsRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import { starLoadingAllReminders } from '../../../actions/contacts/remindersContacts/remindersContact';
import chroma from 'chroma-js';
import * as FIcons from "react-icons/fi";
import * as FAIcons from "react-icons/fa";
import * as Ioicons from "react-icons/io";
import Linkify from 'react-linkify';



export default function AddEditReminders(props) {
    // variables
    const { users: allUsers } = useSelector(state => state.users);

    const { pathname } = useLocation();
    let { id: idInUrl } = useParams();
    const dispatch = useDispatch();
    const [contacts, setContacts] = useState();
    const [selectContact, setSelectContact] = useState(null);
    const [flagEdit, setFlago] = useState(false);
    const { active: activeReminder } = useSelector(state => state.remindersC);
    const { id: IDX } = useSelector(state => state.auth);
    const [selectValue, setSelectValue] = useState();
    let { active: contact } = useSelector(state => state.contacts);

    const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
    const { register, handleSubmit, errors, reset, watch } = useForm({ mode: "onChange" });
    const [modal, setModal] = useState(false);
    const [nameContact, setNameContact] = useState(contact ? contact.name + ' ' + (contact.father_lastname ?? '') + ' ' + (contact.mother_lastname ?? '') : null);
    const [subject, setSubject] = useState("");
    const [users, setUsers] = useState([{}]);
    const [timeReminder, setTimeReminder] = useState("");
    const [dateReminder, setDateReminder] = useState("");
    const [notificationReminder, setNotificationReminder] = useState();
    const [notes, setNotes] = useState("");
    const [departament, setDepartament] = useState("");
    const [values, setValues] = useState([{}]);
    const notificationAlert = useRef();
    const [now, setNow] = useState();
    const [nowTime, setNowTime] = useState();
    const [urgent, setUrgent] = useState(false);
    const [bounds, setBounds] = useState([]);
    const [selectBound, setSelectBound] = useState();
    const [modalPreview, setModalPreview] = useState();
    const [totalRemindersAxios,setTotalRemindersAxios] = useState([]);

    const [previewReminder, setPreviewReminder] = useState({});
    const [flagImportant, setFlagImportant] = useState({
        value: 'Urgente',
        isChecked: false,
        label: 'Urgente'
    });

    // useEffect to Open the previewModal of reminders
    useEffect(() => {

        if (props.flag) {
            let result = props.row;
            showModalPreview(result);
        }

    }, [props])
    useEffect(() => {
        // consultAllBound(idInUrl);
        if (!nameContact) {
            setNameContact(JSON.parse(localStorage.getItem('ActiveContact')) ? JSON.parse(localStorage.getItem('ActiveContact')).name : null)
        }
    }, [])

    useEffect(() => {
        if (activeReminder) {
            consultAllBound(activeReminder.id_contact ?? 0);
        }
        consult();
        present();
        if (activeReminder != null) {
            setActiveReminder();
        }
    }, [activeReminder])

    // Methods
    const formatDatewithRealHours = (dateBD, timeBio) => {
        let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
        let timef = moment(dateBD).add(4, 'h').locale("es-mx").format("h:mm A");
        datef = datef[0].toUpperCase() + datef.slice(1);
        datef = datef.replace(".", "");
        let tag = (
            <span className="montse">
                {datef} <Ioicons.IoMdTime /> {timef}
            </span>
        );
        return tag;
    };

    const showModalPreview = (reminder) => {
        setModalPreview(!modalPreview);
        setPreviewReminder({
            subject: reminder?.subject,
            realTime: reminder?.realTime,
            participants: reminder?.emails_to,
            text: reminder?.notes,
            obj: reminder
        });

    }
    const handleChangeBound = (e) => {
        setSelectBound(e);
    };
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                opacity: 1,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : data.color,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };
    const consultAllBound = async (id) => {
        axios.get(constaApi + "bound/" + id)
            .then(response => {
                const { data } = response;
                const { applications, prospection, trackings } = data;
                let result = [];
                if (applications) {
                    applications.map(ap => {
                        if (ap.status == 'Activo' || ap.status == 'aplicar' || ap.status == 'Cancelado') {
                        } else {
                            result.push({
                                value: ap.id,
                                label: ap.name_prospection + '-' + ap.name,
                                type: "aplicacion",
                                color: 'yellow'
                            });
                        }
                    })
                }
                if (trackings) {
                    trackings.map(ap => {
                        if (ap.status == 'Terminado') {
                        } else {
                            result.push({
                                value: ap.id,
                                label: ap.name_prospection + '-' + ap.name,
                                type: "Seguimiento",
                                color: "rgb(0, 255, 133)"
                            });
                        }
                    })
                }
                if (prospection) {
                    prospection.map(ap => {
                        if (ap.status == 'Activo' || ap.status == 'aplicar' || ap.status == 'cancelar') {
                        } else {
                            result.push({
                                value: ap.id,
                                label: ap.name_prospection,
                                type: "Prospeccion",
                                color: 'rgb(0, 147, 255)'
                            });
                        }
                    })
                }
                result.push({
                    value: 'General',
                    label: 'General',
                    type: 'General',
                    color: 'rgb(190, 192, 144)',
                })
                setBounds([...result]);
            }).catch(err => {
                console.log('catch', err);
            })
    }
    const showDate = (dateBD, timeBio) => {
        let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
        let timef = moment(dateBD).locale("es-mx").format("h:mm A");
        datef = datef[0].toUpperCase() + datef.slice(1);
        datef = datef.replace(".", "");
        let tag = (
            <span className="montse">
                {datef} <Ioicons.IoMdTime /> {timef}
            </span>
        );
        return tag;
    };

    function resetArrays() {
        setFlagImportant({ ...flagImportant, isChecked: false });
    }
    function changeChecked() {
        let check = flagImportant.isChecked;
        check = check ? false : true;
        setFlagImportant({ ...flagImportant, isChecked: check });
    }
    function present() {
        setNow(moment().format("YYYY-MM-DD"));
        setNowTime(moment().format("HH:mm"));
    }
    function setActiveReminder() {
        if (activeReminder.id != null) {
            let array = [];
            activeReminder.emails_to.forEach((element, index) => {
                values.forEach(el => {
                    if (element.email_user === el.email) {
                        array[index] = el;
                    }
                })
            })
            let datex = moment(activeReminder.dateReminder).format('YYYY-MM-DD')
            let timex = moment(activeReminder.dateReminder).format('HH:mm');
            setTimeReminder(timex);
            setDateReminder(datex);
            setFlago(true);
            setFlagImportant({ ...flagImportant, isChecked: activeReminder.urgent == "0" ? false : true });
            if (activeReminder.contact) {
                setNameContact(activeReminder.contact);
            }
            setSubject(activeReminder.subject ?? null);
            setSelectValue(array ?? null);
            setDepartament(activeReminder.departament ?? null);
            setNotes(activeReminder.notes ?? null);
            setNotificationReminder(activeReminder.timenotification ?? null);
            showModal();
        }
    }
    function changeSubject(e) {
        setSubject(e.target.value);
    }
    function changeDepartament(e) {
        setDepartament(e.target.value);
    }
    function changeNotes(e) {
        setNotes(e.target.value);
    }
    function changeTimeReminder(e) {
        let currendate = moment(dateReminder + " " + timeReminder).format("YYYY-MM-DD HH:mm");
        let nowDatecomparison = moment(now + " " + nowTime).format("YYYY-MM-DD HH:mm");
        let strings = e.target.value;
        strings = strings.charAt(1) + strings.charAt(2);
        let nowDate = moment(currendate).subtract(parseInt(strings), 'hour').format("YYYY-MM-DD HH:mm");
        if (nowDate < nowDatecomparison) {
            notification('warning', 'Cuidado,estas ingresando un rango de valor no permitido');
            setNotificationReminder("");
        } else {
            setNotificationReminder(e.target.value);
        }

    }

    function checkAvailability( datecompared ) {

        const data = {
            date : datecompared,
            users: selectValue
        }
        axios.post(constaApi + "remindersCount",data)
        .then(response => {
            setTotalRemindersAxios(response.data)
        });

    }
    function changeDate(e) {
        if(selectValue != undefined){
            checkAvailability(e.target.value);
        } else{
            notification('warning', 'No se han seleccionado usuarios');
        }
        if (e.target.value < now) {
            notification('warning', 'Cuidado,estas ingresando una Fecha menor a la permitida');
        } else {
            setDateReminder(e.target.value)
        }
    }

    function changeTime(e) {
        setTimeReminder(e.target.value);
        // if(e.target.value < nowTime){
        //     notification('warning','Cuidado,estas ingresando una Hora menor a la permitida');
        // } else {
        //     setTimeReminder(e.target.value);
        // }
    }
    const handleChange = (e) => {
        setSelectValue(e);
    }
    const handleChangeSelect = (e) => {
        setSelectContact(e);
        if (e.id) {
            consultAllBound(e.id);
        }
    }
    const consult = async () => {

        let data = {
            id: contact ? contact.id : null,
            idx: IDX
        };
        let result = [];
        let contactsResult = [];
        await axios.post(constaApi + 'defaultSelectBio', data)
            .then(function (response) {
                let { users, contacts: contactx } = response.data;
                users.forEach(us => {
                    result.push({
                        id: us.id,
                        value: us.name + ' ' + us.father_lastname,
                        label: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
                        email: us.email,
                        fullname: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
                        type: us.type,
                    })
                });
                contactx.forEach(us => {
                    contactsResult.push({
                        id: us.id,
                        value: us.name + ' ' + us.father_lastname,
                        label: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
                        email: us.email,
                        fullname: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
                        type: 'contactos',
                    })
                });
                setContacts(contactsResult);
                setValues(result);
            });
    }
    async function showModal() {
        await consultAllBound(idInUrl);
        if (activeReminder) {
            if (activeReminder.type != 'General') {
                switch (activeReminder.type) {
                    case 'Prospeccion':
                        if (activeReminder.prospections) {
                            bounds.map(b => {
                                if (activeReminder.prospections.id == b.value) {
                                    if (activeReminder.type == b.type) {
                                        setSelectBound({ ...b });
                                    }
                                }
                            })
                        }
                        break;

                    case 'Seguimiento':
                        if (activeReminder.trackings) {
                            bounds.map(b => {
                                if (activeReminder.trackings.id == b.value) {
                                    if (activeReminder.type == b.type) {
                                        setSelectBound({ ...b });
                                    }
                                }
                            })
                        }
                        break;

                    case 'Aplicacion':
                        if (activeReminder.applications) {
                            bounds.map(b => {
                                if (activeReminder.applications.id == b.value) {
                                    if (activeReminder.type == b.type) {
                                        setSelectBound({ ...b });
                                    }
                                }
                            })
                        }
                        break;
                }
            } else {
                setSelectBound({
                    value: 'General',
                    label: 'General',
                    type: 'General',
                    color: 'black',
                });
            }
        }
        // if(row.type_prospection == 'General'){
        //     console.log('General');
        //     setSelectBound({
        //       value : 'General',
        //       label: 'General',
        //       type: 'General',
        //       color:'black',
        //     });
        //   } else {
        //     bounds.map(b => {
        //       if(row.id_type_prospection == b.value){
        //         console.log('Here');
        //         if(row.type_prospection == b.type){
        //           setSelectBound({...b});
        //         }
        //       }
        //     })
        //   }
        setModal(!modal);
    }
    async function onSubmit(data) {

        let timex = moment("08:00:00").format('HH:mm');
        console.log('timex',timex);
        let userx = JSON.parse(localStorage.getItem('user'));
        let currendate = moment(dateReminder + " " + timeReminder).format("YYYY-MM-DD HH:mm");
        let nowDatecomparison = moment(now + " " + nowTime).format("YYYY-MM-DD HH:mm");
        let nowDate = moment(currendate).subtract(5, 'minutes').format("YYYY-MM-DD HH:mm");
        if (!(nowDate < nowDatecomparison)) {
            let url = flagEdit ? 'reminders/updated' : 'reminders/save';
            let datex = dateReminder + " " + timeReminder;
            console.log('datex',datex);
            let obj = {
                id: activeReminder ? activeReminder.id : null,
                id_contact: url == 'reminders/updated' ? activeReminder.id_contact : selectContact ? selectContact.id : contact.id ?? null,
                contact: selectContact ? selectContact.fullname : nameContact ?? null,
                subject: subject ?? null,
                emailTo: selectValue ?? null,
                dateReminder: datex ?? null,
                timenotification: notificationReminder ?? "-0 hour",
                notes: notes ?? null,
                departament: props.prospection ? 'prospeccion' : props.applications ? 'aplicacion' : props.trackings ? 'seguimiento' : departament ?? null,
                urgent: flagImportant ? flagImportant.isChecked : null,
                type: props.prospection ? 'Prospeccion' : props.activeApplication ? 'Aplicacion' : props.trackings ? 'Tracking' : 'General',
                id_type: props.activeProspect ? props.activeProspect.id : props.activeApplication ? props.activeApplication.id : props.activeTracking ? props.activeTracking.id : 0,
                selectBound: selectBound,
                responsable: userx.name,
            };
            await axios.post(constaApi + url, obj)
                .then(function (response) {
                    dispatch(setRemindersC([]));
                    if (props.route == 'General') {
                        dispatch(starLoadingRemindersC(contact.id));
                    }
                    if (props.route == 'Dashboard') {
                        dispatch(starLoadingAllRemindersC(props.userDash.id));
                    }
                    if (props.route == 'Section') {
                        dispatch(starLoadingAllReminders(props.userSection.id));
                    }
                    if (props.route == 'Prospeccion') {
                        dispatch(starLoadingProspectRemindersC(contact.id, props.activeProspect.id, 'Prospeccion'));
                    }
                    if (props.route == 'Aplicaciones') {
                        dispatch(starLoadingApplicationRemindersC(contact.id, props.activeApplication.id, 'Aplicaciones'));
                    }
                    if (props.route == 'Trackings') {
                        dispatch(starLoadingTrackingsRemindersC(contact.id, props.activeTracking.id, 'Tracking'));
                    }
                    // if(props.prospection){
                    //     dispatch( starLoadingProspectRemindersC(contact.id,props.activeProspect.id,'Prospeccion'));
                    // }else if(props.applications){
                    //     dispatch( starLoadingApplicationRemindersC(contact.id,props.activeApplication.id,'Aplicaciones'));
                    // }else if(props.trackings){
                    //     dispatch( starLoadingTrackingsRemindersC(contact.id,props.activeTracking.id,'Tracking'));
                    // }else {
                    // }
                }).catch(error => {

                });
            dispatch(activeReminderC(null, null));
            // if(init && pathname != '/contacts/'+idInUrl+'/reminders'){
            // dispatch(starLoadingAllReminders(init.id));
            // } else {
            //     dispatch(starLoadingRemindersC(contact.id));
            // }
            handleClose();
        } else {
            notification('warning', 'Cuidado,esta se encuentra entre el rango no permitido');
        }
    }
    function handleClose() {
        setSelectBound();
        resetArrays();
        dispatch(activeReminderC(null, null));
        setTimeReminder(null);
        setDateReminder(null);
        setSubject(null);
        setSelectValue(null);
        setSelectContact(null);
        setDepartament(null);
        setNotes(null);
        setNotificationReminder(null);
        setModal(false);
        setFlago(false);
    }
    const styles = {
        container: {
            width: "80%",
            margin: "0 auto",
        },
        input: {
            width: "100%",
        },
    };
    function notification(type, message) {
        let place = "tc";
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        }

        notificationAlert.current.notificationAlert(options);
    }
    const showParticipant = (type = "use", name, fullname = "") => {
        let n = fullname ? fullname.split(" ") : " ";
        let tag = '';
        if (n.length >= 3) {
            n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
        } else if (n.length >= 2) {
            n = n[0].charAt(0) + n[1].charAt(0);
        } else {
            n = n[0].charAt(0);
        }
        switch (type) {
            case 'user':
                allUsers.map(al => {
                    if (fullname == (al.name + " " + al.father_lastname + " " + al.mother_lastname)) {
                        switch (al.type) {
                            case 'Administrador':
                                tag = <span className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgBlue">{n}</span>;
                                break;
                            case 'Supervisor':
                                tag = <span className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgPurple">{n}</span>;
                                break;
                            case 'Colaborador ':
                            default:
                                tag = <span className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgGreen">{n}</span>;

                                break;
                        }
                    }
                })
                break;
            case 'contactos':
                tag = <span className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgTurquesa">{n}</span>;
                break;
            case 'colegio':
                tag = <span className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink">{n}</span>;
                break;
            default:
                tag = <span className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink">{n}</span>;
                break;
        }
        return tag;
    };

    const closeModalPreview = (e) => {
        props.bridge(previewReminder);
        setModalPreview(!modalPreview);
    }
    const handleEdit = (obj) => {
        props.bridge(obj);
        setModalPreview(false);
        dispatch(activeReminderC(obj.id, obj));
    }

    const whatColorIs = ( totalCount ) => {

        if(totalCount < 15){
            return 'Green';
        }
        if( totalCount == 15 || totalCount <= 20){
            return '#e1e902';
        }
        if( totalCount > 20){
            return '#ff0000';
        }
    }

    return (
        <div className="mt-n5">
            <NotificationAlert ref={notificationAlert} />
            <button
                disabled={props.blocked ? true : false}
                onClick={(e) => showModal()} className="btn btn-info">
                <span className="Inter"
                    style={{ fontSize: "18px" }}>+</span> Recordatorio</button>

            <Modal
                style={{ marginTop: "50px" }}
                dialogClassName="modal-90w"
                show={modal}
                onHide={e => handleClose()}
            >
                <Modal.Header style={{ height: "60px" }} closeButton>
                    <Modal.Title style={{ fontFamily: "Inter", marginTop: "5px", fontWeight: "600", fontSize: "18px" }}>
                        {flagEdit ? 'Actualizar Recordatorio' : 'Agregar Recordatorio'} </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="container-fluid">

                            <Row>
                                {!props.openContacts || flagEdit
                                    ?
                                    <Col>
                                        <Form.Label className="formGray">Nombre</Form.Label>
                                        <Form.Control name="name"
                                            disabled
                                            autoComplete="off" className="formGray" type="text" placeholder="Ingrese su nombre"
                                            value={nameContact}
                                        />
                                    </Col>
                                    :
                                    <Col>
                                        <Form.Label className="formGray">Contacto</Form.Label>
                                        <Select
                                            name="values"
                                            value={selectContact}
                                            onChange={(e) => handleChangeSelect(e)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecciona un contacto"
                                            isLoading={true}
                                            options={contacts}
                                        />
                                    </Col>
                                }
                            </Row>

                            <Row className="mt-3">
                                <Col className="col-8">
                                    <Form.Label className="formGray">Asunto</Form.Label>
                                    <Form.Control name="subject"
                                        onChange={(e) => changeSubject(e)}
                                        style={{ letterSpacing: '0.2px' }}
                                        autoComplete="off" className="formGray montse" type="text" placeholder="Escriba el asunto..."
                                        value={subject}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <Form.Label className="formGray">Usuarios</Form.Label>
                                    {values &&
                                        <Select
                                            isMulti
                                            name="values"
                                            value={selectValue}
                                            onChange={(e) => handleChange(e)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Selecciona un usuario"
                                            isLoading={true}
                                            options={values}
                                        />
                                    }
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col >
                                    <Form.Control style={{ height: '100px', width: '180px' }}
                                        onChange={(e) => changeDate(e)}
                                        value={dateReminder} autoComplete="off" name="date"
                                        className="formGray" type="date" placeholder="Ingrese su Fecha" />
                                </Col>
                                <Col className="mt-4 col-2">
                                    <label className="custom-radio-checkbox">
                                        <input className="custom-radio-checkbox__input"
                                            value={flagImportant}
                                            checked={flagImportant.isChecked} type="checkbox" onChange={(e) => changeChecked(e)} />
                                        <span className="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                                        <span className="custom-radio-checkbox__text">Especial</span>
                                    </label>
                                </Col>
                                <Col className='col-4'>
                                    {
                                        totalRemindersAxios.length > 0 &&
                                      (
                                            <h8 style={{fontWeight:'bold'}}>Cantidad de recordatorios :</h8>

                                      )
                                    }
                                {totalRemindersAxios.map(total => {
                                    return(
                                        <>
                                       <p style={{
                                        marginTop:'5px',
                                        fontWeight:'bold',
                                        color: whatColorIs(total.totalReminders)
                                      }} >{total.name_user} : {total.totalReminders}</p>
                                        </>
                                    )
                                })}
                                </Col>
                                {
                                    flagImportant.isChecked == true ?
                                        <>
                                            <Col className="mt-4">
                                                <Form.Control style={{ height: '30px', width: '120px' }}
                                                    onChange={(e) => changeTime(e)}
                                                    value={timeReminder} autoComplete="off" name="date"
                                                    className="formGray" type="time" placeholder="Ingrese su Fecha" />
                                            </Col>
                                            <Col className="col-5">
                                                <Form.Label className="formGray">Notificación</Form.Label>
                                                <Form.Control onChange={(e) => changeTimeReminder(e)}
                                                    autoComplete="off"
                                                    value={notificationReminder} name="type" as="select" size="sm" custom>
                                                    <option disabled selected value=""></option>
                                                    <option value="-0 hour">Misma hora</option>
                                                    <option value="-1 hour">1 Hora Antes</option>
                                                    <option value="-24 hour">1 Día Antes</option>
                                                    <option value="-48 hour">2 Días Antes</option>
                                                    <option value="-168 hour">1 Semana Antes</option>
                                                </Form.Control>
                                            </Col>
                                        </>
                                        :
                                        <>
                                <Col className="mt-4">
                                    </Col>
                                                                    <Col className="col-5">
                                                                        </Col>
                                        </>
                                }

                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <Form.Label className="formGray">Notas</Form.Label>
                                    <InputGroup style={{ borderTop: '0', width: '100%', marginTop: '0px' }}>
                                    <InputGroup.Text
  style={{whiteSpace:"pre-wrap",width:'100%',  backgroundColor: "#FFFFFF",textAlign:'start'}}
                                              onChange={(e) => changeNotes(e)}
                                            rows={9}
                                            value={notes} as="textarea" placeholder="Escriba su mensaje..." rows={8} />
                                    </InputGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-7">
                                    {bounds && (
                                        <Select
                                            name="values"
                                            value={selectBound}
                                            onChange={(e) => handleChangeBound(e)}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            options={bounds}
                                            styles={colourStyles}
                                        />
                                    )}
                                </Col>

                                {/* <Col className="mt-4 col-6">
                                    <label className="custom-radio-checkbox">
                                        <input className="custom-radio-checkbox__input"
                                            value={flagImportant}
                                            checked={flagImportant.isChecked} type="checkbox" onChange={(e) => changeChecked(e)} />
                                        <span className="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                                        <span className="custom-radio-checkbox__text">Urgente</span>
                                    </label>
                                </Col> */}
                            </Row>

                        </div>

                        <Row>
                            <Col>
                                <Button
                                    disabled={
                                        flagImportant.isChecked ?
                                        !subject ? true
                                        : !dateReminder ? true
                                        : !timeReminder ? true
                                        : !notificationReminder ? true
                                        : !notes ? true
                                        : !selectValue ? true
                                        : !selectBound ? true
                                        : false

                                        :

                                        !subject ? true
                                        : !dateReminder ? true
                                        : !notes ? true
                                        : !selectValue ? true
                                        : !selectBound ? true
                                        : false

                                    }
                                    className="float-right mb-3 mr-2 montse" type="submit"
                                    onSubmit={handleSubmit(onSubmit)}
                                    variant="info">{flagEdit ? 'Actualizar' : 'Guardar'}</Button>
                                <Button onClick={handleClose} className="float-right mb-3 mr-2 montse btnBee" >
                                    Cancelar
                                </Button>

                            </Col>
                        </Row>

                    </form>
                </Modal.Body>
            </Modal>

            {/* Modal of Preview  */}
            {/* FirstModal */}
            <Modal
                show={modalPreview}
                dialogClassName="modal-90w"
                onHide={(e) => closeModalPreview(e)}
                style={{ marginTop: "50px" }}
            >
                <Modal.Body style={{ background: "#F4F5F6", border: "1px" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="container">
                            <Row>
                                <div style={{ fontSize: "18px" }} className="col montse">
                                    {previewReminder.subject}
                                </div>
                                <div className="justify-content-end">
                                    <a>
                                        <FIcons.FiEdit
                                            onClick={(e) => handleEdit(previewReminder?.obj)}
                                            size={18}
                                            style={{ color: "#386CEF" }}
                                        />
                                    </a>
                                    {/* <FAIcons.FaTrashAlt
                      className="ml-1"
                      style={{ color: "#DC3545" }}
                      size={18}
                      onClick={(e) => {
                        deleteComment(previewReminder.id);
                      }}
                    /> */}
                                </div>
                            </Row>
                            <Row className="mt-2">
                                <div style={{ fontSize: "14px" }} className="col montse ">
                                    <svg
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        style={{
                                            color: "rgb(66, 82, 110)",
                                            height: "16px",
                                            width: "14px",
                                        }}
                                    >
                                        <g fill="none" fillRule="evenodd">
                                            <path
                                                fill="currentColor"
                                                fillRule="nonzero"
                                                d="M14 11H9v5h5v-5zM13 0v2H5V0H3v2H2C.89 2 .01 2.9.01 4L0 18a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-1V0h-2zm3 18H2V7h14v11z"
                                            ></path>
                                            <path d="M-3-1h24v24H-3z"></path>
                                        </g>
                                    </svg>{" "}
                                    {showDate(previewReminder.realTime, previewReminder.timeBio)}
                                </div>
                            </Row>
                            {previewReminder.participants && (
                                <Row className="mt-3">
                                    <div style={{ fontSize: "14px" }} className="col montse">
                                        Participantes:
                                        {previewReminder.participants.map((part) => (
                                            <Row key={part.id} className="mt-2">
                                                {showParticipant(part.type_user, part.name, part.name_user)}
                                                <span className="ml-2 mt-1">{part.name_user}</span>
                                            </Row>
                                        ))}
                                    </div>
                                </Row>
                            )}
                            <Row>

                                <div
                                    // onClick={(e) => handleEdit(previewReminder?.obj)}
                                    style={{ width: '100%', backgroundColor: "white" }}
                                    className="ml-3 mt-3 "
                                >
                                    {previewReminder.subject === 'Se le enviaron propuestas'
                                        ?
                                        Object.keys(previewReminder.proposals).map((name, i) => {
                                            return (
                                                [previewReminder.proposals[name].map(r => {
                                                    return (
                                                        <li>
                                                            {r.name}
                                                        </li>
                                                    )
                                                })]
                                            )
                                        })
                                        :
                                        <div
                                            style={{ whiteSpace: "pre-wrap", color: '#7A859C', fontSize: '16px' }}
                                        >
                                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                                <a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>
                                            )}>

                                                {previewReminder.text}
                                            </Linkify>
                                        </div>

                                    }

                                </div>

                            </Row>
                            <Row
                                style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <div
                                    style={{
                                        backgroundColor: "white",
                                        whiteSpace: "pre-wrap", color: '#7A859C',
                                        fontSize: '16px', position: 'absolute', right: 20
                                    }}>
                                    {props.row?.name_responsable &&
                                        <span
                                            className="mt-1 mb-1 mr-1 ml-1">
                                            Creado por {props.row?.name_responsable} , {props.row?.created_at ? formatDatewithRealHours(props.row.created_at) : ''}
                                        </span>
                                    }
                                </div>
                            </Row>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>


        </div>


    )
}
