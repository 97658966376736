import React from 'react'
import DetailsData from './DetailsData'


function Detail() {
    return (
        <>
            <div className="content">
            <DetailsData />
            </div>
        </>
    )
}

export default Detail
